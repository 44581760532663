@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900');


html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
* {
  box-sizing: border-box;
  scrollbar-width: 'none';
}
button, input[type="submit"], input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

a {
  text-decoration: none;  /* Removes underline */
  color: inherit;         /* Inherits the text color from the parent element */
}

@keyframes fadeInSlideIn {
  from {
    opacity: 0;
    transform: translateX(-100px); /* Starts 100 pixels to the left */
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade-in-slide-in {
  animation: fadeInSlideIn ease-out 0.6s;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.fade-in {
  animation: fadeIn ease 0.2s;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
html {
  /* Dark to navy-blue gradient 
  background: linear-gradient(135deg, #0f1018 0%, #112240 50%, #0d1b2a 100%);
  */
  background: linear-gradient(291deg, #16171f, #1a212e 50%, #1c1e21);

  
  background-attachment: fixed;
  background-size: cover;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}



input {
  all: unset;
}
body {
  line-height: 1;
  background: linear-gradient(165deg,rgba(118,120,136,0.1) 3.89%,rgba(118,120,136,0) 66.42%);
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 4px;
  width: 8px;
  background: #fff;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 4px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 16px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 28px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 4px;
    height: 32px;
  }
  50%, 100% {
    top: 12px;
    height: 16px;
  }
}

.slideIn {
  animation: slideIn 0.2s cubic-bezier(0, 0.9, 0.8, 1);
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}